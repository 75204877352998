// src/context/AuthContext.jsx

import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, onAuthStateChanged, db } from '../firebase/firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  serverTimestamp
} from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

// Mapeo de tipos de cuenta a meditaciones máximas por mes
const MAX_MEDITATIONS_PER_MONTH_MAP = {
  free: 5,
  premium: 60,
  super: 200,
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [typeAccount, setTypeAccount] = useState('free'); // Estado para el tipo de cuenta
  const [meditationsThisMonth, setMeditationsThisMonth] = useState(0);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('es'); // Incluye el idioma si es necesario
  const [voice, setVoice] = useState('default'); // Nuevo estado para la voz
  const [meditationType, setMeditationType] = useState('default'); // Nuevo estado para el tipo de meditación
  const [hasSeenWelcome, setHasSeenWelcome] = useState(false); // Nuevo estado

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        await fetchUserData(user);
        await fetchMeditationsCount(user);
        await fetchHasSeenWelcome(user); // Obtener el estado de bienvenida
      } else {
        setTypeAccount('free'); // Por defecto 'free' si no hay usuario
        setMeditationsThisMonth(0);
        setLanguage('es');
        setVoice('default');
        setMeditationType('default');
        setHasSeenWelcome(false);
      }
      setLoading(false);
    });

    // Limpia la suscripción al desmontar
    return unsubscribe;
  }, []);

  // Función para obtener los datos del usuario, incluyendo typeAccount, language, voice y meditationType
  const fetchUserData = async (user) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setTypeAccount(userData.typeAccount || 'free'); // Establece 'free' si no existe
        setLanguage(userData.language || 'es'); // Establece 'es' si no existe
        setVoice(userData.voice || 'default'); // Establece 'default' si no existe
        setMeditationType(userData.meditationType || 'default'); // Establece 'default' si no existe
      } else {
        // Si el documento no existe, crea uno con valores por defecto
        await setDoc(doc(db, 'users', user.uid), {
          uid: user.uid,
          displayName: user.displayName || 'Sin Nombre',
          email: user.email,
          typeAccount: 'free', // Tipo de cuenta por defecto
          language: 'es', // Idioma por defecto
          voice: 'default', // Voz por defecto
          meditationType: 'default', // Tipo de meditación por defecto
          createdAt: serverTimestamp(),
          hasSeenWelcome: false, // Inicializar el campo
        });
        setTypeAccount('free');
        setLanguage('es');
        setVoice('default');
        setMeditationType('default');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setTypeAccount('free'); // Fallback a 'free' en caso de error
      setLanguage('es');
      setVoice('default');
      setMeditationType('default');
    }
  };

  // Función para obtener el conteo de meditaciones del mes actual
  const fetchMeditationsCount = async (user) => {
    try {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const startOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);

      const meditationsRef = collection(db, 'meditations');
      const q = query(
        meditationsRef,
        where('userId', '==', user.uid),
        where('createdAt', '>=', startOfMonth),
        where('createdAt', '<', startOfNextMonth)
      );

      const querySnapshot = await getDocs(q);
      setMeditationsThisMonth(querySnapshot.size);
    } catch (error) {
      console.error('Error fetching meditations count:', error);
      // Opcional: Manejar el error de manera adecuada, por ejemplo, estableciendo un estado de error
    }
  };

  // Función para obtener si el usuario ha visto el pop-up de bienvenida
  const fetchHasSeenWelcome = async (user) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        setHasSeenWelcome(data.hasSeenWelcome || false);
      }
    } catch (error) {
      console.error('Error fetching hasSeenWelcome:', error);
      setHasSeenWelcome(false);
    }
  };

  // Función para incrementar el conteo de meditaciones (llamada después de una meditación exitosa)
  const incrementMeditationsCount = () => {
    setMeditationsThisMonth((prev) => prev + 1);
  };

  // Determina el máximo de meditaciones según el tipo de cuenta
  const MAX_MEDITATIONS_PER_MONTH = MAX_MEDITATIONS_PER_MONTH_MAP[typeAccount] || 5;

  const value = {
    currentUser,
    typeAccount, // Añadido para exponer el tipo de cuenta
    meditationsThisMonth,
    MAX_MEDITATIONS_PER_MONTH,
    incrementMeditationsCount,
    language,
    setLanguage, // Si deseas manejar el idioma desde el contexto
    voice, // Exponer la voz seleccionada
    setVoice, // Exponer la función para actualizar la voz
    meditationType, // Exponer el tipo de meditación
    setMeditationType, // Exponer la función para actualizar el tipo de meditación
    hasSeenWelcome, // Exponer el estado
    setHasSeenWelcome, // Exponer la función para actualizar
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
