// src/components/LoadingScreen.jsx

import React from 'react';
import './LoadingScreen.css'; // Importa los estilos que necesites

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      {/* Agrega tu animación o mensaje de carga aquí */}
      <h2>Cargando...</h2>
    </div>
  );
};

export default LoadingScreen;
