// src/App.jsx

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen'; // Importa el componente LoadingScreen

// Carga tus componentes de forma perezosa
const MeditationForm = lazy(() => import('./components/MeditationForm'));
const Login = lazy(() => import('./components/Login'));
const Signup = lazy(() => import('./components/Signup'));
const PrivateRoute = lazy(() => import('./components/PrivateRoute'));
const Result = lazy(() => import('./components/Result'));
const CartillaAnimica = lazy(() => import('./components/CartillaAnimica'));
const WaitList = lazy(() => import('./components/WaitList'));
const Stats = lazy(() => import('./components/Stats'));
const Landing = lazy(() => import('./components/Landing'));
const WelcomePopUp = lazy(() => import('./components/WelcomePopUp'));
const Configuration = lazy(() => import('./components/Configuration'));
const Journal = lazy(() => import('./components/Journal'));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {/* Ruta principal: muestra Landing si no está autenticado, o MeditationForm si lo está */}
          <Route
            path="/"
            element={
              <PrivateRoute fallback={<Landing />}>
                <>
                  <MeditationForm />
                  <WelcomePopUp />
                </>
              </PrivateRoute>
            }
          />
          <Route path="/result" element={<Result />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/waitlist" element={<WaitList />} />
          <Route path="/cartilla" element={<CartillaAnimica />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/config" element={<Configuration />} />
          <Route path="/journal" element={<Journal />} />

          {/* Agrega más rutas según sea necesario */}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
